import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import _ from 'lodash'
import BackgroundImage from 'gatsby-background-image'

import logoWhite from "../images/enimeris-logo-website-white.png";
import "./../components/index.css"




function Index(props) {
    const data = props.data.wpgraphql
    console.log(data)
    return (
        <div>
            <Helmet>
                <html lang={"el"} />
                <title>{data.page.seo.title}</title>
                <meta name="description" content={data.page.seo.description} />
                <link rel="canonical" href={data.page.seo.canonical} />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
                <meta property="og:title" content={data.page.seo.opengraphTitle} />
                <meta property="og:description" content={data.page.seo.opengraphDescription} />
                <meta property="og:image" content={data.page.seo.opengraphImage ? data.page.seo.opengraphImage.sourceUrl : ""} />
                <meta property="og:image:width" content="598" />
                <meta property="og:image:height" content="399" />
            </Helmet>
            <BackgroundImage
                Tag="section"
                className="background-intro-container"
                fluid={data.page.carreerPageAcf.backgroundImage.imageFile.childImageSharp.fluid}
                backgroundColor={`#040e18`}
            >
                <div className="absolute">
                    <div className="text-white flex justify-center">
                        <a href={"https://enimeris.com"}>
                            <img className="w-56 md:w-64 m-auto py-8" src={logoWhite} alt="white-logo" />
                        </a>
                    </div>
                </div>

                <div className="m-auto flex flex-wrap h-full md:h-screen">
                    <div className="md:w-1/2 bg-effect flex items-center justify-center pt-32 md:pt-0 ">
                        <div className="text-center mx-4 md:mx-0">
                            <div>
                                <h1 className="text-5xl text-white">
                                    {data.page.carreerPageAcf.title}
                                </h1>
                            </div>
                            <div className="max-w-2xl my-12">
                                <p className="text-white text-xl font-bold tracking-widest">
                                    {data.page.carreerPageAcf.description}
                                </p>
                            </div>
                            <div>
                                <Link  className="index-cta1 px-6 py-3 ease-linear text-white block m-12 md:inline md:m-0" to={`/ergasia-stin-enimeris/`}>
                                    {data.page.carreerPageAcf.buttonText}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-1/2 bg-effect2 flex items-center justify-center pt-16 md:pt-0">
                        <div className="text-center mx-4 md:mx-0">
                            <div>
                                <h2 className="text-5xl" style={{color: "#173d6a"}}>
                                    {data.page.carreerPageAcf.title2}
                                </h2>
                            </div>
                            <div className="max-w-2xl my-12">
                                <p className="text-xl font-bold tracking-widest" style={{color: "#173d6a"}}>
                                    {data.page.carreerPageAcf.description2}
                                </p>
                            </div>
                            <div>
                                <Link  className="index-cta2 px-6 py-3 ease-linear block m-12 md:inline md:m-0" to={`/synergates/`}>
                                    {data.page.carreerPageAcf.buttonText2}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>

        </div>
    )
}


export default Index

export const pageQuery = graphql`
	query {
		wpgraphql {
			page(id: "cG9zdDoxNzQ0"){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                carreerPageAcf{
                    backgroundImage{
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2680,quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    title
                    title2
                    buttonText
                    buttonText2
                    buttonUrl
                    buttonUrl2
                    description
                    description2
                }
            }
		}
	}
`
